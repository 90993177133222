import { createHotReloadFunction } from "./frontend/hotreload-client";
import { patientApiClient } from "./frontend/api-client";

const input = document.getElementById("input-code");
const btn = document.getElementById("submit-btn");
const errorLabel = document.getElementById("error-message");
if (!(input instanceof HTMLInputElement)) {
  throw new Error("input is not an input element");
}

if (!(btn instanceof HTMLButtonElement)) {
  throw new Error("btn is not a button element");
}

if (!(errorLabel instanceof HTMLLabelElement)) {
  throw new Error("error-label is not a label-element");
}

const C = {
  BTN_TEXT: "Send inn",
  BTN_LOADING_TEXT: "Sjekker kode...",
  ERROR_TEXT: "Det oppsto en feil. Kanskje du skrev feil kode?",
  ERROR_TEXT_EMPTY: "",
};

const setErrorState = (err: string) => {
  errorLabel.innerText = err;
  input.focus();
  input.disabled = false;
  btn.disabled = input.value.length !== 4;
  btn.removeAttribute("aria-busy");
  btn.innerText = C.BTN_TEXT;
};

const setLoadingState = () => {
  input.disabled = true;
  errorLabel.innerText = C.ERROR_TEXT_EMPTY;
  btn.innerText = C.BTN_LOADING_TEXT;
  btn.disabled = true;
  btn.setAttribute("aria-busy", "true");
};

const setNormalState = () => {
  input.disabled = false;
  btn.disabled = input.value.length !== 4;
  btn.removeAttribute("aria-busy");
  btn.innerText = C.BTN_TEXT;
  errorLabel.innerText = C.ERROR_TEXT_EMPTY;
};

input.value = "";

input.oninput = (e) => {
  btn.disabled = input.value.length !== 4;
  errorLabel.innerText = C.ERROR_TEXT_EMPTY;
};

const dummySubmit = () => {
  setTimeout(() => {
    const error = Math.random() > 0.5;
    console.log("Got error: " + error);
    if (error) {
      setErrorState(C.ERROR_TEXT);
      return;
    } else {
      setNormalState();
    }
  }, 2000);
};
btn.onclick = async (ev) => {
  ev.preventDefault();
  ev.stopPropagation();
  setLoadingState();
  // dummySubmit();

  const val = input.value;
  patientApiClient.codeSubmit(val).then((res) => {
    console.log(res);
    if (res.ok) {
      setNormalState();
      window.location.href = res.value.redirectTo;
    } else {
      setErrorState(C.ERROR_TEXT);
      // setErrorState()
      // SHOW ERROR
    }
  });
};
btn.innerText = C.BTN_TEXT;
btn.disabled = input.value.length !== 4;
btn.removeAttribute("aria-busy");
input.value = "";

createHotReloadFunction("kode-side");
