import { z } from "zod";
import {
  BuilderSchemaDtoValidator,
  CodeBookValidator,
  EngineSchemaDtoValidator,
  SchemaConfigValidator,
} from "./VALIDATION";

export const PublicationCreateDto = z.object({
  schemaName: z.string(),
  shortDescription: z.string(),
  longDescription: z.string(),
  mediaQuestVersion: z.string(),
  builderDto: BuilderSchemaDtoValidator,
  engineDto: EngineSchemaDtoValidator,
  codeBook: CodeBookValidator,
  schemaConfig: SchemaConfigValidator,
});

export type PublicationCreateDto = z.infer<typeof PublicationCreateDto>;

export const PublicationCreateResponse = z.object({
  id: z.string().nonempty("Schema ID must not be empty"),
  version: z.number().int().positive("Version must be positive"),
  slug: z.string().nonempty("Slug must not be empty"),
});

export type PublicationCreateResponse = z.infer<
  typeof PublicationCreateResponse
>;
export const PreConfigurationCode = z.object({
  blockAutoplayQuestion: z.boolean().optional(),
  blockAutoplayVideo: z.boolean().optional(),
});
