export interface Ok<T> {
  readonly ok: true;
  readonly value: T;
}
export interface Err {
  readonly ok: false;
  readonly error: string;
}

export type Result<T> = Ok<T> | Err;

const ok = <T>(value: T): Ok<T> => ({ ok: true, value });
const err = (error: string): Err => ({ ok: false, error });
export const Result = {
  ok,
  err,
};
