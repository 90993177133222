import { Origin, WindowUtil } from "./WindowUtil";

const HOT_RELOAD_POLLING_INTERVAL = 1000;
const refreshFn = (origin: Origin, appName: string) => {
  const url = [origin, "dev", "hot-reload", appName].join("/");
  return function pollingFunction() {
    fetch(url)
      .then((res) => {
        return res.json();
      })

      .then((data) => {
        if (data.isFirstHit) {
          console.log("Did reload .");
          window.location.reload();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
};

export const createHotReloadFunction = (appName: string): void => {
  if (WindowUtil.isDev()) {
    const origin = WindowUtil.getOrigin();
    const pollingFunction = refreshFn(origin, appName);
    setInterval(pollingFunction, HOT_RELOAD_POLLING_INTERVAL);
  } else {
    console.log("Not in dev mode, skipping hot reload.");
  }
};
