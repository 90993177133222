export type Origin = string & { __origin: true };
export type PathSegment = string & { __pathSegment: true };
export type Path = ReadonlyArray<PathSegment>;

const getOrigin = (): Origin => {
  if (!window) {
    throw new Error("NO WINDOW OBJECT");
  }
  const location = window.location;
  const origin = location.origin as Origin;
  return origin;
};

const isDev = () => {
  return getOrigin().includes("localhost");
};

const isProd = () => {
  return !isDev();
};

const isPathSegment = (s: string): s is PathSegment => {
  return s.length > 0;
};

const getPathSegments = (): ReadonlyArray<PathSegment> => {
  const path = window.location.pathname;
  const segments = path.split("/").filter(isPathSegment);
  return segments;
};

const getPathSegment = (position: number): PathSegment | false => {
  const segments = getPathSegments();
  if (segments.length < position) {
    return false;
  }
  return segments[position - 1];
};
const rect = () => {
  return {
    w: window.outerWidth,
    h: window.outerHeight,
    width: window.innerWidth,
    height: window.innerHeight,
  };
};
export const WindowUtil = {
  rect,
  getPathSegment,
  getPathSegments,
  getOrigin,
  isDev,
  isProd,
};
