import { SchemaDto } from "@media-quest/engine";
import { U } from "./common/U";
import { BuilderSchemaDto } from "@media-quest/builder";
import { z } from "zod";

const isBuilderSchemaDto = (schema: any): schema is BuilderSchemaDto => {
  const parsed = BuilderSchemaDtoValidator.safeParse(schema);
  if (U.isBrowser) {
    console.log(parsed);
  }
  if (parsed.success) {
    const d = parsed.data;
    // TODO TYPE CHECK.
    // const dto: BuilderSchemaDto = {pages: d.pages, id: d.id, name: d.name};
  }
  return parsed.success;
};

const isEngineSchemaDto = (schema: any): schema is SchemaDto => {
  const parsed = EngineSchemaDtoValidator.safeParse(schema);
  if (U.isBrowser) {
    console.log(parsed);
  }
  return parsed.success;
};

// TODO add validation for all properties.
export const BuilderSchemaDtoValidator = z
  .object({
    name: z.string(),
    id: z.string().nonempty("Schema ID must not be empty"),
    pages: z.array(z.any()),
  })
  .nonstrict();

const VariableValidator = z
  .object({ label: z.string(), varId: z.string() })
  .nonstrict();

export const SchemaConfigValidator = z
  .object({
    schemaId: z.string(),
    schemaName: z.string(),
    schemaPrefix: z.string(),
    variables: z.array(z.any()),
  })
  .nonstrict();

export const CodeBookValidator = z.object({
  predefinedVariables: z.array(z.any()),
  pageVariables: z.array(z.any()),
});

export const EngineSchemaDtoValidator = z
  .object({
    baseHeight: z.number().positive(),
    baseWidth: z.number().positive(),
    pages: z.array(z.any()),
  })
  .nonstrict();
export const VALIDATION = {
  isBuilderSchemaDto,
  isEngineSchemaDto,
};
