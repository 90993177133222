import { WindowUtil } from "./WindowUtil";
import { PublicationCreateDto, PublicationCreateResponse } from "../dto";
import { U } from "../common/U";
import { Result } from "../common/result";
import { SchemaDto, Fact, MqEvent } from "@media-quest/engine";
import { VALIDATION } from "../VALIDATION";
import { z } from "zod";
const baseURL = [WindowUtil.getOrigin(), "api"].join("/");
const adminApiKeyURL = [baseURL, "admin-apikey"].join("/");
const patientURL = [baseURL, "patient"].join("/");

const CodeSubmitResponse = z.object({
  redirectTo: z.string().nonempty("Redirect to must be nonempty"),
});

const postBody = (body: object) => {
  return {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "content-type": "application/json;charset=utf-8",
    },
  };
};

let apiKey = "abc";

export const setApiKey = (key: string) => {
  apiKey = key;
};

const addKey = (init: RequestInit): RequestInit => {
  return {
    ...init,
    redirect: "follow",
    headers: {
      ...init.headers,
      "x-api-key": apiKey,
    },
  };
};

const answerSubmit = async (
  code: string,
  answers: Array<Fact>,
  eventLog: Array<MqEvent>,
  pagesLeft: number
) => {
  const url = [patientURL, "answer-submit"].join("/");
  const body = {
    code,
    answers,
    eventLog,
    pagesLeft,
  };
  const init = postBody(body);

  const response = await fetch(url, init);
  const json = await response.json();
  return json;
};

const codeSubmit = async (
  code: string
): Promise<Result<{ redirectTo: string }>> => {
  const url = [patientURL, "code-submit"].join("/");
  console.log(url);
  const body = {
    code,
  };
  const init = postBody(body);

  try {
    const response = await fetch(url, { ...init });
    const json = await response.json();
    const parsed = CodeSubmitResponse.safeParse(json);
    if (!parsed.success) {
      return Result.err(parsed.error.message);
    }

    return Result.ok(parsed.data);
  } catch (e) {
    const m = U.parseError(e, "Ugyldig kode eller feil ved innsending");
    return Result.err(m);
  }
};

const publish = async (
  dto: PublicationCreateDto,
  apiKey: string
): Promise<PublicationCreateResponse> => {
  const url = [adminApiKeyURL, "publish"].join("/");
  setApiKey(apiKey);
  const body = postBody(dto);
  const init = addKey(body);
  try {
    const response = await fetch(url, init);
    const json = await response.json();
    console.log(json);
    const parsed = PublicationCreateResponse.parse(json);
    console.log(parsed);
    return parsed;
  } catch (e) {
    console.log(e);
    throw new Error("Error while publishing schema");
  }

  // const parsed = PublicationDto.parse(json);
  // return parsed;
};

const schemaGetLatest = async (
  name: string,
  version: "latest" | number,
  code: string | false
): Promise<Result<SchemaDto>> => {
  try {
    let url = [patientURL, "preview", name, version].join("/");
    if (code && code.length) {
      url = url + "?kode=" + code;
    }
    const response = await fetch(url);
    const json = await response.json();
    return VALIDATION.isEngineSchemaDto(json)
      ? Result.ok(json)
      : Result.err("Invalid schema");
  } catch (e) {
    const message = U.parseError(e, "Error while getting schema");
    return Result.err(message);
  }
};

// const
export const adminApiClient = {
  publish,
  setApiKey,
} as const;

export const patientApiClient = {
  schemaGetLatest,
  codeSubmit,
  answerSubmit,
};
